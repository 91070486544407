import React, { useMemo, useState, useEffect, useRef } from "react";
import Axios from "axios";
import {
  useNavigate,
  generatePath,
  useLocation,
} from "react-router-dom";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import "../Assets/Additionalcss/Journey.css";
import { useDispatch, useSelector } from "react-redux";
import { fetchTodos } from "../redux/slice/todo";
import { Image_URL } from "../Config/Base";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Arrow from "../Components/Arrow";
import Stack from "@mui/material/Stack";
import Skeleton from "@mui/material/Skeleton";

function Journey(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const reduxData = useSelector((state) => state.todo.data);
  const [searchTerm, setSearchTerm] = useState("");
  const [opensearchbox, setopensearchbox] = useState(false);
  const [alphabetletter, setAlphabetletter] = useState("");
  const [searchyear, setSearchyear] = useState("");
  const [Load, setLoad] = useState(true);
  const [data, setData] = useState([]);
  const [filtdata, setFiltdata] = useState([]);
  const [intialdata, setIntialData] = useState([]);
  const searchBoxRef = useRef(null);

  const year = ["2024", "2023", "2022", "2021", "2020", "2019", "2018", "2017", "All"];
  const letters = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
    "All",
  ];

const checkdata = () =>{
  Axios({
    method: "GET",
    url: `https://testingapi.kdpfoundation.org/api/Foundation/getJourney`,
  }).then((resp) => {
console.log("ddd", resp)

// setData(resp)
  });
}

  console.log("data", data)
  const memoHeader = useMemo(() => <Header />, []);
  const memoFooter = useMemo(() => <Footer />, []);

  const memoFilter = useMemo(
    () => (
      <div>
        <div className="container">
          <div className="row">
            <div className="grid-contain">
              {letters.map((item, k) => (
                <div
                  key={k}
                  className={
                    alphabetletter === item
                      ? "col grid active-grid"
                      : "col grid"
                  }
                  onClick={(e) => passletter(item, e)}
                >
                  {item}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    ),
    [alphabetletter]
  );

  const memoYear = useMemo(
    () => (
      <div className="dis-top">
        <div className="container">
          <div className="row">
            <div className="grid-contain">
              {year.map((item, k) => (
                <div
                  key={k}
                  className={
                    searchyear === item
                      ? "col grid active-grid font-reduce"
                      : "col grid font-reduce"
                  }
                  onClick={(e) => passdata(item, e)}
                >
                  {item}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    ),
    [searchyear]
  );

  const opensearch = () => {
    setopensearchbox(!opensearchbox);
    setSearchTerm("");
  };

  const handleProceed = (id, loc) => {
    navigate(generatePath("/Newseemore/:id", { id }), {
      state: { eletter: alphabetletter, eMlocation: loc, eyear: searchyear },
    });
  };

  const passdata = (item, e) => {
    if (!alphabetletter) {
      setSearchyear(item);
      // setAlphabetletter("All");
    } else {
      setSearchyear(item);
    }
  };

  const passletter = (item, e) => {
    setAlphabetletter(item);
    setSearchTerm("")
  };

  const setDataFromRedux = () => {
    const formattedData = reduxData.map((d) => d);
    setData(formattedData);
    setIntialData(formattedData.slice(0, 6));
    filterData(formattedData);
  };

  const filterData = (ss) => {
    const schoolEvents = ss.filter((e) => e.event_name.includes("School"));
    const otherEvents = ss.filter((e) => !e.event_name.includes("School"));
    const uniqueSchoolEvents = schoolEvents.reduce((acc, current) => {
      const y = acc.find(
        (item) =>
          item.event_name === current.event_name &&
          item.event_location === current.event_location
      );
      if (!y) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);

    const combinedEvents = otherEvents.concat(uniqueSchoolEvents);
    setFiltdata(combinedEvents);
    setLoad(false);
  };

  const handleClickOutside = (event) => {
    if (searchBoxRef.current && !searchBoxRef.current.contains(event.target)) {
      setopensearchbox(false);
    }
  };

  const filterEvents = () => {
    return data.filter((item) => {
      const eventYear = new Date(item.event_date).getFullYear();
      const eventLocationFirstLetter = item.event_location
        .charAt(0)
        .toUpperCase();
      const matchesSearchTerm =
        item.event_location.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.event_name.toLowerCase().includes(searchTerm.toLowerCase());

      if (searchTerm) {
        return matchesSearchTerm;
      }

      if (alphabetletter === "All" && searchyear !== "All") {
        return eventYear === parseInt(searchyear) && matchesSearchTerm;
      }

      if (!alphabetletter || alphabetletter === "All") {
        if (searchyear === "All" || searchyear === "") {
          return matchesSearchTerm;
        }
        return eventYear === parseInt(searchyear) && matchesSearchTerm;
      }

      if (searchyear === "All" || searchyear === "") {
        return (
          eventLocationFirstLetter === alphabetletter && matchesSearchTerm
        );
      } else {
        const matchesYear = eventYear === parseInt(searchyear);
        const matchesLetter = eventLocationFirstLetter === alphabetletter;
        return matchesYear && matchesLetter && matchesSearchTerm;
      }
    });
  };

  useEffect(() => {
    if (!reduxData) {
      dispatch(fetchTodos());
    } else {
      setDataFromRedux();
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dispatch, reduxData]);

  useEffect(() => {
    if (reduxData && reduxData.length > 0) {
      setDataFromRedux();
    }
    const filterText = location.state ? location.state.eletter : "";
    const filterYear = location.state ? location.state.eyear : "";
    setAlphabetletter(filterText);
    setSearchyear(filterYear);
  }, [reduxData, location.state]);

  return (
    <>
      <div className="Journey-container">
        {memoHeader}
        <Arrow />
        <div className="reduce-container">
          <div className="container">
            <div className="row">
              <div className="grid-contain">
                {!opensearchbox ? (
                  <input
                    type="text"
                    className="searchgrid"
                    placeholder="Search"
                    onClick={opensearch}
                    onChange={() => {}} // Prevent uncontrolled input warning
                  />
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <div>
        {opensearchbox ? (
          <div className="reduce-container">
            <div className="container">
              <div className="row">
                <div className="search-grid-contain" ref={searchBoxRef}>
                  <p onClick={opensearch} className="closeicon">
                    <i className="fa-solid fa-xmark"></i>
                  </p>
                  <input
                    type="text"
                    className="searchgrid2"
                    placeholder="Search"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                  <br />
                  <div className="grid-contain">
                    {letters.map((item, k) => (
                      <div
                        key={k}
                        className={
                          alphabetletter === item
                            ? "col grid active-grid"
                            : "col grid"
                        }
                        onClick={(e) => passletter(item, e)}
                      >
                        {item}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
        </div>
        <div className="reduce-container">{memoYear}</div>
        <div className="container">
          <div className="row">
            <div className="cards-list">
              {Load ? (
                <div className="container">
                  <div className="row">
                    <div className="cards-list">
                      <div className="top-skeleton">
                        <Stack spacing={15}>
                          <div className="skeleton">
                            <div className="skeleton-div">
                              <Skeleton
                                variant="rectangular"
                                width={280}
                                height={250}
                              />
                              <Skeleton variant="text" />
                              <Skeleton variant="text" />
                            </div>
                            <div className="skeleton-div">
                              <Skeleton
                                variant="rectangular"
                                width={280}
                                height={250}
                              />
                              <Skeleton variant="text" />
                              <Skeleton variant="text" />
                            </div>
                            <div className="skeleton-div">
                              <Skeleton
                                variant="rectangular"
                                width={280}
                                height={250}
                              />
                              <Skeleton variant="text" />
                              <Skeleton variant="text" />
                            </div>
                          </div>
                          <div className="skeleton">
                            <div className="skeleton-div">
                              <Skeleton
                                variant="rectangular"
                                width={280}
                                height={250}
                              />
                              <Skeleton variant="text" />
                              <Skeleton variant="text" />
                            </div>
                            <div className="skeleton-div">
                              <Skeleton
                                variant="rectangular"
                                width={280}
                                height={250}
                              />
                              <Skeleton variant="text" />
                              <Skeleton variant="text" />
                            </div>
                            <div className="skeleton-div">
                              <Skeleton
                                variant="rectangular"
                                width={280}
                                height={250}
                              />
                              <Skeleton variant="text" />
                              <Skeleton variant="text" />
                            </div>
                          </div>
                        </Stack>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                filterEvents().length === 0 ? (
                  <div>No Event Found</div>
                ) : (
                  filterEvents().map((item) => (
                    <div
                      className="journeycard"
                      key={item.id}
                      onClick={(e) => handleProceed(item.id, item.event_location)}
                    >
                      <div className="journey-date">
                        <p className="journey-dat">
                          <span className="journey-da">
                            {new Date(item.event_date).getDate()}
                          </span>
                          <br />
                          {new Date(item.event_date).toLocaleString(
                            "default",
                            { month: "short" }
                          )}
                        </p>
                      </div>
                      <LazyLoadImage
                        className="card-img"
                        alt={`${Image_URL}${item.image_path}`}
                        effect="blur"
                        src={`${Image_URL}${item.image_path}`}
                      />
                      <div className="card-body">
                        <h6 className="card-title">
                          <b>{item.event_name}</b> at {item.event_location}
                        </h6>
                        <div
                          onClick={(e) => handleProceed(item.id, item.event_location)}
                        >
                          See More
                        </div>
                      </div>
                    </div>
                  ))
                )
              )}
            </div>
          </div>
        </div>
      </div>
      {memoFooter}
    </>
  );
}

export default Journey;